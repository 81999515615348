//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 3px !important;
        border: none;
    }
}
.page-link.active, .active > .page-link {
    z-index: 0;
}